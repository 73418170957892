<template>
  <div class="center-screen">
    <div class="space-y-4 mb-8">
      <div class="logo">
        <img src="../assets/Icon.png" alt="Logo" width="120">
        <h1 class="font-bold text-6xl text-dark dark:text-light">Walletvote</h1>
      </div>
      <h2 class="text-2xl mb-20">Bring <b class="bold">democracy</b> to the <b class="bold">economy</b></h2>
      <div>
        <p>The Walletvote dApp and DAO will be released soon...</p>
        <p>In the meantime, feel free to read our Litepaper and reach out to us!</p>
      </div>
      <div class="grid grid-cols-2 gap-x-4">
        <a href="./Walletvote_Litepaper.pdf" target="_blank" class="cta">Read our Litepaper</a>
        <button @click="$emit('message', true)" class="cta">Contact the team</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeScreen',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center-screen {
  @apply flex flex-col px-6 justify-center items-center text-center min-h-screen space-y-4;
}

.bold {
  @apply font-semibold text-brand;
}

p {
  @apply italic;
}

.logo {
  @apply flex flex-col items-center justify-center space-x-0 space-y-0;
  @apply sm:flex-row sm:space-x-8 sm:space-y-0;
}
</style>
