<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Walletvote` : `Walletvote` }}</template>
  </metainfo>
  <router-view/>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body {
  font-family: 'Montserrat', sans-serif;
}

.cta {
  @apply p-3 border-0 border-solid rounded-md text-lg font-semibold;
  @apply border-green-600 bg-brand text-green-700 hover:bg-brand-dark;
}
</style>
