<template>
  <div class="home">
    <Welcome @message="toggleContactModal" />
    <ContactModal @close="toggleContactModal" v-show="contactModalOpened" />
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import Welcome from '@/components/Welcome.vue'
import ContactModal from '@/components/ContactModal.vue'

export default {
  name: 'HomeView',
  components: {
    Welcome,
    ContactModal,
  },
  setup () {
    useMeta({ title: 'Welcome' })
  },
  data () {
    return {
      contactModalOpened: false
    }
  },
  methods: {
    toggleContactModal(bool) {
      this.contactModalOpened = bool;
    }
  }
}
</script>

<style scoped>
.home {
    color: white;
    background-image: linear-gradient(45deg, rgb(0, 36, 2), rgb(125, 255, 140));
}
</style>
